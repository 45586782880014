<!--
  Blocks are production time lapse values
  rendered as controls if downtime is unjustified.
  -->
<template>
  <section :wxid="$options.name" class="timeline-blocks-container">
    <v-tooltip top v-for="(marker, index) in shiftMarkers" :key="`ws-start-${index}`" class="pa-0">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          class="shift-marker"
          :class="{ 'manually-created-marker': marker.work_shift_start_properties?.is_manually_created }"
          :key="`shift-marker-${index}`"
          :style="{
            left: leftPosition(marker.start),
          }"
        >
        </div>
      </template>
      <span>{{ formatTime(marker.original_start) }}</span>
    </v-tooltip>

    <template v-for="(block, index) in timeline.blocks">
      <!--   Block is down and user can justify and the duration is greater than X min   -->
      <v-tooltip
        v-if="isClickable(block)"
        :key="`timeline-block-down-${index}`"
        :right="showTooltipAtRight(block)"
        :left="showTooltipAtLeft(block)"
        :content-class="getTooltipContentClasses(block)"
        :nudge-left="calculateNudgeLeft(block)"
        :nudge-right="calculateNudgeRight(block)"
      >
        <template v-slot:activator="{ on, attrs }">
          <button
            v-bind="attrs"
            v-on="on"
            class="block"
            :class="clickableTimelineBlockCssClass(block)"
            @click="selectDowntime(block.downtime.id)"
            :style="{
              left: leftPosition(block.start),
              width: blockWidth(block.duration),
            }"
          >
            <template v-for="(sub_block, indexSubBlock) in block.sub_blocks">
              <span
                :class="clickableTimelineSubBlockCssClass(sub_block, block)"
                :key="`timeline-subblock-${indexSubBlock}-${sub_block.duration}`"
                :style="{
                  width: subBlockWidth(block.duration, sub_block.duration),
                }"
              ></span>
            </template>
          </button>
        </template>
        <div>
          <div class="mt-2">
            <span class="font-weight-bold">{{ getBlockStatusLabel(block) }}</span>
            <span class="ml-2 font-weight-bold">{{ getBlockDurationText(block) }}</span>
          </div>

          <div>
            <span class="font-weight-light">{{ getBlockPeriod(block) }}</span>
          </div>

          <div v-if="isSingleJustification(block)">
            <span class="font-weight-light ellipsis">{{ getJustificationName(getJustifications(block)[0]) }}</span>
          </div>
          <ul v-if="isMultipleJustifications(block)" class="align-start align-content-start">
            <li v-for="(j, reasonIndex) in getJustifications(block)" :key="`tooltip-reason-${reasonIndex}`">
              <div class="ml-3 d-flex flex-column">
                <span class="font-weight-light">{{ bullet }} {{ getJustificationName(j) }}</span>
                <span class="ml-2 font-weight-light">{{ getJustificationPeriod(j) }}</span>
              </div>
            </li>
          </ul>
        </div>
      </v-tooltip>

      <!--   Block is down and user can justify but the duration is less than X min   -->
      <v-tooltip
        v-if="
          isBlockDown(block.state) && (!isJustifiableDowntime(block) || isBlockOverlappingOop(block)) && !isPresenter
        "
        right
        allow-overflow
        offset-overflow
        :key="`timeline-unclick-block-${index}`"
        :disabled="!showUnclickableBlockTooltip(block)"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-if="hasSubBlocks(block)"
            v-on="on"
            v-bind="attrs"
            class="block"
            :class="unclickableTimelineBlockCssClass(block, index)"
            :style="{
              left: leftPosition(block.start),
              width: blockWidth(block.duration),
            }"
          >
            <!-- This case can happen if a downtime has been justified from a scheduled downtime
            and its duration is under the justification delay
            OR manually justified at a moment when the justification delay was lower
            -->
            <span
              v-for="(sub_block, indexSubBlock) in block.sub_blocks"
              :class="clickableTimelineSubBlockCssClass(sub_block, block)"
              :key="`timeline-subblock-${indexSubBlock}-${sub_block.duration}`"
              :style="{ width: subBlockWidth(block.duration, sub_block.duration) }"
            ></span>
          </div>
          <div
            v-else
            v-on="on"
            v-bind="attrs"
            class="block"
            :class="unclickableTimelineBlockCssClass(block, index)"
            :style="{
              left: leftPosition(block.start),
              width: blockWidth(block.duration),
            }"
          ></div>
        </template>
        <div class="wx-info-window">
          <span v-if="isBlockOverlappingOop(block)">
            {{ $t("timeline.blocks.inProgressOop") }}
          </span>
          <span v-else-if="hasSubBlocks(block)" class="text">
            {{ $t("timeline.blocks.noJustificationRequiredMinutes", { delay: formattedJustificationDelay }) }}.
            {{ $t("timeline.blocks.withSubBlockButNoJustificationRequiredMinutes") }}
          </span>
          <span v-else class="text">
            {{ $t("timeline.blocks.noJustificationRequiredMinutes", { delay: formattedJustificationDelay }) }}
          </span>
        </div>
      </v-tooltip>

      <!--   User is presenter, should not be able to click on any blocks   -->
      <v-tooltip
        v-if="!isClickable(block)"
        :key="`timeline-block-up-${index}`"
        :right="showTooltipAtRight(block)"
        :left="showTooltipAtLeft(block)"
        :content-class="getTooltipContentClasses(block)"
        :nudge-left="calculateNudgeLeft(block)"
        :nudge-right="calculateNudgeRight(block)"
      >
        <template v-slot:activator="{ on, attrs }">
          <button
            v-bind="attrs"
            v-on="on"
            class="block"
            :class="unclickableTimelineBlockCssClass(block, index)"
            :style="{
              left: leftPosition(block.start),
              width: blockWidth(block.duration),
            }"
          >
            <template v-for="(sub_block, indexSubBlock) in block.sub_blocks">
              <span
                :class="clickableTimelineSubBlockCssClass(sub_block, block)"
                :key="`timeline-subblock-${indexSubBlock}-${sub_block.duration}`"
                :style="{
                  width: subBlockWidth(block.duration, sub_block.duration),
                }"
              ></span>
            </template>
          </button>
        </template>
        <div>
          <div class="mt-2">
            <span class="font-weight-bold">{{ getBlockStatusLabel(block) }}</span>
            <span class="ml-2 font-weight-bold">{{ getBlockDurationText(block) }}</span>
          </div>

          <div>
            <span class="font-weight-light">{{ getBlockPeriod(block) }}</span>
          </div>

          <div v-if="isSingleJustification(block)">
            <span class="font-weight-light ellipsis">{{ getJustificationName(getJustifications(block)[0]) }}</span>
          </div>
          <ul v-if="isMultipleJustifications(block)" class="align-start align-content-start">
            <li v-for="(j, reasonIndex) in getJustifications(block)" :key="`tooltip-reason-${reasonIndex}`">
              <div class="ml-3 d-flex flex-column">
                <span class="font-weight-light">{{ bullet }} {{ getJustificationName(j) }}</span>
                <span class="ml-2 font-weight-light">{{ getJustificationPeriod(j) }}</span>
              </div>
            </li>
          </ul>
        </div>
      </v-tooltip>
    </template>

    <div v-if="!coverageIsOneHourOrLess" class="progress-marker" :style="{ left: periodProgressPercentage }">
      <span class="d-sr-only">{{ periodProgressPercentageFloored }}</span>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { DateTime } from "luxon";
import { DATE_TIME_FORMAT_NO_TIMEZONE, TIME_FORMAT_HH_MM_SS } from "@/store/TimeUtils";
import DurationUtils from "@/components/DurationUtils";
import { dash, bullet } from "@/helpers";
import Accessibility from "@/components/Accessibility";

export default {
  name: "TimelineProductionBlocks",
  computed: {
    ...mapGetters("dashboard", [
      "timeline",
      "selectedDowntime",
      "selectedDowntimeId",
      "shiftMarkers",
      "isBlockDown",
      "isJustifiableDowntime",
      "currentProductionUnitDataSourceAlerts",
      "activePUJustificationDelayInSeconds",
      "isBlockOverlappingOop",
    ]),
    ...mapGetters("user", ["isPresenter"]),
    ...mapGetters("navigation", ["activeFactory", "startDateTime"]),
    bullet() {
      return bullet;
    },
    coverageIsOneHourOrLess() {
      return this.timeline.coverageDuration <= 3600;
    },
    periodProgressPercentage() {
      return (this.timeline.coverageProgress / this.timeline.coverageDuration) * 100 + "%";
    },
    periodProgressPercentageFloored() {
      return Math.floor((this.timeline.coverageProgress / this.timeline.coverageDuration) * 100) + "%";
    },
    formattedJustificationDelay() {
      const hours = parseInt(this.activePUJustificationDelayInSeconds / 3600, 10);
      const minutes = parseInt((this.activePUJustificationDelayInSeconds % 3600) / 60, 10);
      if (hours > 0) {
        return `${hours} ${this.$t("common.hours")} ${minutes} ${this.$t("common.minutes")}`;
      } else if (minutes > 0) {
        return `${minutes} ${this.$t("common.minutes")}`;
      } else {
        // The `activePUJustificationDelayInSeconds` is under a minute
        return `${this.activePUJustificationDelayInSeconds} ${this.$t("common.seconds")}`;
      }
    },
  },
  methods: {
    ...mapActions("dashboard", ["selectDowntime"]),
    isClickable(block) {
      return (
        this.isBlockDown(block.state) &&
        this.isJustifiableDowntime(block) &&
        !this.isPresenter &&
        !this.isBlockOverlappingOop(block)
      );
    },
    showTooltipAtRight(block) {
      let closeToTheEndRatio = block.start / this.timeline.coverageDuration;
      return closeToTheEndRatio < 0.7;
    },
    showTooltipAtLeft(block) {
      let atRight = this.showTooltipAtRight(block);
      return !atRight;
    },
    getTooltipContentClasses(block) {
      if (this.showTooltipAtRight(block)) {
        return "tooltip-right pt-1 pb-2 pl-4";
      } else {
        return "tooltip-left pt-1 pb-2 pl-4";
      }
    },
    calculateNudgeLeft(block) {
      if (this.showTooltipAtRight(block)) {
        return ((block.duration / this.timeline.coverageDuration) * window.innerWidth) / 2;
      }
      return 0;
    },
    calculateNudgeRight(block) {
      if (this.showTooltipAtLeft(block)) {
        const durationInPixels = (block.duration / this.timeline.coverageDuration) * window.innerWidth;
        return durationInPixels / 2;
      }
      return 0;
    },
    getBlockStatusLabel(block) {
      switch (block.state) {
        case "up":
          return this.$t("timeline.blocks.status.active");
        case "down_planned":
          return this.$t("timeline.blocks.status.planned");
        case "down_unplanned":
          return this.$t("timeline.blocks.status.unplanned");
        case "down_unjustified":
          return this.$t("timeline.blocks.status.unjustified");
        case "down_mixed":
          return this.$t("timeline.blocks.status.mixed");
        case "out_of_production":
          return this.$t("timeline.blocks.status.outOfProduction");
        case "unknown":
          return this.$t("timeline.blocks.status.unknown");
        case "carbon-frozen":
          return this.$t("timeline.blocks.status.disconnected");
        default:
          return "";
      }
    },
    getBlockPeriod(block) {
      let blockStartDateTime = this.startDateTime.plus({ seconds: block.start });
      let blockEndDateTime = this.startDateTime.plus({ seconds: block.start + block.duration });
      let start = blockStartDateTime.toFormat(TIME_FORMAT_HH_MM_SS);
      let end = blockEndDateTime.toFormat(TIME_FORMAT_HH_MM_SS);
      return `${start} ${dash} ${end}`;
    },
    getJustificationPeriod(j) {
      return `${j.justificationStartTime} ${dash} ${j.justificationEndTime}`;
    },
    getBlockDurationText(block) {
      return DurationUtils.secondsToHHMMSS(block.duration);
    },
    isJustified(block) {
      switch (block.state) {
        case "down_planned":
        case "down_unplanned":
        case "down_mixed":
          return true;
        default:
          return false;
      }
    },
    getJustifications(block) {
      // return early here and continue on with the rest of the code
      if (!this.isJustified(block)) return [];

      let downtime = block.downtime;
      let reasons = [];
      downtime.justifications.forEach((j) => {
        let justificationEndTime = j.end_time ? j.end_time : downtime.end_time;
        const durationMillis = justificationEndTime - j.start_time;
        const durationText = DurationUtils.millisToHHMMSS(durationMillis);
        let reason;
        if (j.reason) {
          reason = {
            reasonCode: j.reason.code,
            reasonName: j.reason.name,
            durationText: durationText,
            justificationStartTime: DateTime.fromMillis(j.start_time).toFormat(TIME_FORMAT_HH_MM_SS),
            justificationEndTime: DateTime.fromMillis(justificationEndTime).toFormat(TIME_FORMAT_HH_MM_SS),
          };
        } else {
          reason = {
            durationText: durationText,
            justificationStartTime: DateTime.fromMillis(j.start_time).toFormat(TIME_FORMAT_HH_MM_SS),
            justificationEndTime: DateTime.fromMillis(justificationEndTime).toFormat(TIME_FORMAT_HH_MM_SS),
          };
        }
        reasons.push(reason);
      });
      return reasons;
    },
    getJustificationName(j) {
      if (j.reasonCode) {
        return `${j.reasonCode} ${dash} ${j.reasonName} ${j.durationText}`;
      } else {
        return `${this.$t("timeline.blocks.status.unjustified")} ${j.durationText}`;
      }
    },
    isMultipleJustifications(block) {
      return this.getJustifications(block).length > 1;
    },
    isSingleJustification(block) {
      return this.getJustifications(block).length === 1;
    },
    formatTime(milliseconds) {
      return DateTime.fromMillis(milliseconds, { zone: this.activeFactory.timezone }).toFormat(
        DATE_TIME_FORMAT_NO_TIMEZONE,
      );
    },
    hasSubBlocks(block) {
      return block.sub_blocks && block.sub_blocks.length > 0;
    },
    showUnclickableBlockTooltip(block) {
      if (
        this.timeline &&
        this.timeline.blocks &&
        this.timeline.blocks.length > 0 &&
        this.selectedDowntimeId === null
      ) {
        return (
          block.state === "down_unplanned" ||
          block.state === "down_unjustified" ||
          block.state === "down_planned" ||
          block.state === "down_mixed"
        );
      }
      return false;
    },
    clickableTimelineBlockCssClass(block) {
      if (this.selectedDowntime) {
        if (this.isJustifiableDowntime(block)) {
          return [
            /* Based on the state of the block */ this.getBlockStateCssClass(block.state),
            /* If Downtime & selected */ block.downtime.id === this.selectedDowntime.id ? "selected" : "",
          ];
        } else {
          return "hidden";
        }
      } else {
        return [
          /* Based on the state of the block */ this.getBlockStateCssClass(block.state),
          /* If Downtime & selected */ this.selectedDowntime && block.downtime.id === this.selectedDowntime.id
            ? "selected"
            : "",
        ];
      }
    },
    clickableTimelineSubBlockCssClass(subBlock, parentBlock) {
      if (this.selectedDowntime) {
        if (this.isJustifiableDowntime(parentBlock)) {
          return [
            /* Based on the state of the block */ this.getBlockStateCssClass(subBlock.state),
            /* If Downtime & selected */ parentBlock.downtime.id === this.selectedDowntime.id ? "selected" : "",
          ];
        } else {
          return "hidden";
        }
      } else {
        return [
          /* Based on the state of the block */ this.getBlockStateCssClass(subBlock.state),
          /* If Downtime & selected */ this.selectedDowntime && parentBlock.downtime.id === this.selectedDowntime.id
            ? "selected"
            : "",
        ];
      }
    },
    unclickableTimelineBlockCssClass(block, index) {
      if (this.selectedDowntime) {
        return this.getHiddenBlockCssClass();
      } else {
        const isAlerts =
          this.currentProductionUnitDataSourceAlerts && this.currentProductionUnitDataSourceAlerts.length > 0;
        return isAlerts && index === this.timeline.blocks.length - 1
          ? this.getDisconnectedBlockCssClass()
          : this.getBlockStateCssClass(block.state);
      }
    },
    leftPosition(blockStart) {
      return 100 / (this.timeline.coverageDuration / blockStart) + "%";
    },
    blockWidth(blockDuration) {
      return 100 / (this.timeline.coverageDuration / blockDuration) + "%";
    },
    subBlockWidth(blockDuration, subBlockDuration) {
      return 100 / (blockDuration / subBlockDuration) + "%";
    },
    getBlockStateCssClass(state) {
      if (state !== "down_mixed") {
        return Accessibility.addPalette(state);
      } else {
        return state;
      }
    },
    getHiddenBlockCssClass() {
      return Accessibility.addPalette("hidden");
    },
    getDisconnectedBlockCssClass() {
      return Accessibility.addPalette("connection_issues");
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-blocks-container {
  $containerDefaultHeight: 40px;

  position: relative;
  flex: 1 0 auto;
  height: ($containerDefaultHeight * 2);
  background-image: var(--repeating-oblique-small-pattern);

  @media ($wx-isNotMobile) {
    height: $containerDefaultHeight;
    background-image: var(--repeating-oblique-pattern);
  }
}

// timeline.blocks template
.block {
  position: absolute;
  height: 100%;
  top: 0;
  width: 10px;
  transition: background-color 0.3s linear;

  &:focus {
    outline: none;
  }

  &.selected {
    z-index: 1;
    outline: 2px dashed var(--color-warning);
  }

  // --------------------------------------------------------------------------------------------------
  // Up
  // --------------------------------------------------------------------------------------------------
  &.up {
    background-color: var(--color-uptime);
  }
  &.up-accessible {
    background-color: var(--color-uptimeAccessible);
  }
  // --------------------------------------------------------------------------------------------------
  // Unjustified
  // --------------------------------------------------------------------------------------------------
  &.down_unjustified,
  .down_unjustified {
    background-color: var(--color-unjustifiedDowntime);
  }
  &.down_unjustified-accessible,
  .down_unjustified-accessible {
    background-color: var(--color-unjustifiedDowntimeAccessible);
  }
  // --------------------------------------------------------------------------------------------------
  // Unplanned
  // --------------------------------------------------------------------------------------------------
  &.down_unplanned,
  .down_unplanned {
    background-color: var(--color-justifiedDowntime);
  }
  &.down_unplanned-accessible,
  .down_unplanned-accessible {
    background-color: var(--color-justifiedDowntimeAccessible);
  }
  // --------------------------------------------------------------------------------------------------
  // Planned
  // --------------------------------------------------------------------------------------------------
  &.down_planned,
  .down_planned {
    background-color: var(--color-plannedDowntime);
  }
  &.down_planned-accessible,
  .down_planned-accessible {
    background-color: var(--color-plannedDowntimeAccessible);
  }
  // --------------------------------------------------------------------------------------------------
  // Planned
  // --------------------------------------------------------------------------------------------------
  &.out_of_production {
    background-color: var(--color-outOfProduction);
  }
  &.out_of_production-accessible {
    background-color: var(--color-outOfProductionAccessible);
  }
  // --------------------------------------------------------------------------------------------------
  // Planned
  // --------------------------------------------------------------------------------------------------
  &.unknown {
    background-color: var(--color-unknown);
  }
  &.unknown-accessible {
    background-color: var(--color-unknownAccessible);
  }
  // --------------------------------------------------------------------------------------------------
  // Planned
  // --------------------------------------------------------------------------------------------------
  &.connection_issues {
    background-color: var(--color-disconnected);
  }
  &.down_mixed {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }
  &.hidden {
    background-color: var(--color-timeline-graph-background);
  }
}

// shiftMarkers template
.shift-marker {
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  z-index: 10;
  left: 50%;
  background-color: currentColor;
  transition: 0.1s linear;

  &:after,
  &:before {
    content: "";
    position: absolute;
    width: 11px;
    height: 2px;
    left: -5px;
    top: 25%;
    background-color: currentColor;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }

  &.manually-created-marker {
    opacity: 0.6;
  }
}

.shift-marker:hover {
  width: 3px;
  &:before {
    margin-left: 1px;
    transition: 0.1s linear;
    transform: rotate(-45deg) scale(1.66);
  }
  &:after {
    margin-left: 1px;
    transition: 0.1s linear;
    transform: rotate(45deg) scale(1.66);
  }
}

.progress-marker {
  position: absolute;
  top: -6px;
  width: 3px;
  height: calc(100% + 17px);
  background-color: currentColor;
}

.v-tooltip__content {
  background-color: var(--color-inverted-container-theme);
}

.wx-info-window {
  max-width: 220px;
  color: var(--color-inverted-text-theme);
  background-color: var(--color-inverted-container-theme);

  .text {
    opacity: 0.9;
  }
}

.defaultMouseCursor {
  cursor: default;
}

.tooltip-right {
  background-color: var(--color-base-theme);
  color: var(--color-text-theme);
  opacity: 1;
}
.tooltip-right::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -8px;
  border-top: solid 8px transparent;
  border-bottom: solid 8px transparent;
  border-left: solid 8px transparent;
  border-right: solid 8px var(--color-base-theme);
}
.tooltip-right.primary::before {
  border-right: solid 8px var(--color-base-theme) !important;
}

.tooltip-left {
  background-color: var(--color-base-theme);
  color: var(--color-text-theme);
  opacity: 1;
}
.tooltip-left::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%; /* To the left of the tooltip */
  margin-top: -8px;
  border-top: solid 8px transparent;
  border-bottom: solid 8px transparent;
  border-left: solid 8px var(--color-base-theme);
  border-right: solid 8px transparent;
}
.tooltip-left.primary::before {
  border-left: solid 8px var(--color-base-theme) !important;
}
</style>
